@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-style: normal;
  src: url('./Inter-Regular.woff2') format('woff2'),
       url('./Inter-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-style: italic;
  src: url('./Inter-Italic.woff2') format('woff2'),
       url('./Inter-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  font-style: normal;
  src: url('./Inter-Medium.woff2') format('woff2'),
       url('./Inter-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  font-style: italic;
  src: url('./Inter-MediumItalic.woff2') format('woff2'),
       url('./Inter-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  font-style: normal;
  src: url('./Inter-SemiBold.woff2') format('woff2'),
       url('./Inter-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  font-style: italic;
  src: url('./Inter-SemiBoldItalic.woff2') format('woff2'),
       url('./Inter-SemiBoldItalic.woff') format('woff');
}
