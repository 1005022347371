.EventLabel-title {
  font-size: 11px;
  font-weight: 600;
}

.EventLabel-text {
  font-size: 11px;
  font-weight: 400;
}

.Line {
}

.Line-label {
  font-size: 11px;
  font-weight: 500;
  text-anchor: middle;
}
