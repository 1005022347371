@font-face {
  font-family: 'IBM Plex Serif';
  font-weight: 400;
  font-style: normal;
  src: url('./IBMPlexSerif-Regular.woff2') format('woff2'),
       url('./IBMPlexSerif-Regular.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Serif';
  font-weight: 400;
  font-style: italic;
  src: url('./IBMPlexSerif-Italic.woff2') format('woff2'),
       url('./IBMPlexSerif-Italic.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Serif';
  font-weight: 500;
  font-style: normal;
  src: url('./IBMPlexSerif-Medium.woff2') format('woff2'),
       url('./IBMPlexSerif-Medium.woff') format('woff');
}
