.Filters {
  margin-bottom: 2rem;
}

.FilterRow {
  font-size: 0.8125rem;
  line-height: 1.1;
  margin-bottom: 1rem;
}

.FilterRow strong {
  display: block;
  vertical-align: middle;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.Filters {
  position: relative;
}

.Legend {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0.75rem;
}

.LegendItem {
  margin-top: 0.5rem;
  margin-right: 0.75rem;
}

.LegendDot {
  position: relative;
  top: 1px;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 0.4rem;
}

.RadioButton {
  display: inline-block;
  padding: 0.35rem 0.55rem 0.4rem;
  vertical-align: middle;
  border: 1px transparent solid;
  border-radius: 3px;
  border-color: rgba(187, 187, 187, 0.75);
  position: relative;
  margin: 0.125rem;
  z-index: 1;
  transition: border-color 100ms ease, background-color 100ms ease,
    color 100ms ease;
}

.RadioButton--selected {
  border-color: rgba(126, 153, 187, 0.75);
  background-color: rgba(126, 153, 187, 0.15);
  color: #122c4e;
  z-index: 4;
  /* background-color: rgba(0, 0, 0, 0.15); */
}

.RadioButton input[type='radio'] {
  position: absolute;
  appearance: none;
  border: none;
}

@media only screen and (min-width: 767px) {
  .FilterRow {
    margin-bottom: 0.5rem;
  }

  .FilterRow strong {
    display: inline-block;
    margin-right: 0.5rem;
    margin-bottom: 0;
  }

  .RadioButton {
    border-radius: 0;
    margin: 0;
    margin-left: -1px;
  }

  .RadioButton:first-of-type {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .RadioButton:last-of-type {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}
