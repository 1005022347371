@import './assets/fonts/inter.css';
@import './assets/fonts/plex.css';

:root {
  --serif: 'IBM Plex Serif', Georgia, Times, serif;
  --sans: 'Inter', -apple-system, BlinkMacSystemFont, 'avenir next', avenir,
    helvetica, 'helvetica neue', ubuntu, roboto, noto, 'segoe ui', arial,
    sans-serif;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
blockquote,
figure {
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--sans);
  font-size: 100%;
  font-feature-settings: 'liga' 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img,
svg,
video {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: inherit;
  font-weight: normal;
}
