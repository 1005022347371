:root {
  --containerWidth: 37.5rem;
  --headerOffset: 1.25rem;
}

.Eyebrow {
  color: #888;
  margin-left: 0.12rem;
  margin-bottom: 0.45rem;
  font-size: 0.75rem;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.Title {
  margin-left: -0.12rem;
  font-size: 2rem;
  font-weight: normal;
  line-height: 1.15;
  letter-spacing: -1px;
}

.Loading {
  padding: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40vh;
  text-align: center;
}

.container {
  max-width: var(--containerWidth);
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.container.wide {
  max-width: calc(var(--containerWidth) + var(--headerOffset) * 2);
}

.Article {
  font-family: var(--serif);
}

.Article-headerReturnLink {
  background-color: #272829;
  display: block;
  padding: 0.85rem var(--headerOffset) 0.75rem;
  font-family: var(--sans);
  font-size: 0.85rem;
  color: #e2e2e2;
  text-decoration: none;
  transition: background-color 200ms ease, color 200ms ease;
}

.Article-headerReturnLink:hover {
  background-color: #fdb839;
  color: #fff;
}

.Article-header {
  background-color: #f7f5f2;
  padding: 1.5rem var(--headerOffset) 1.5rem;
  margin-bottom: 2rem;
}

.Article-authors {
  color: #666;
  font-family: var(--sans);
  font-size: 0.8125rem;
}

.Article-authors span {
  font-size: 0.95em;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

.Article-title {
  color: #333333;
  font-size: 2.125rem;
  font-weight: 500;
  line-height: 1.12;
  letter-spacing: -0.03rem;
  margin-bottom: 0.65rem;
  max-width: 27ch;
}

.Article-subtitle {
  color: #333333;
  font-size: 1.75rem;
  line-height: 1.17;
  letter-spacing: -0.02rem;
  margin-bottom: 1.75rem;
}

.Article-notes {
  color: #666;
  font-family: var(--sans);
  font-size: 0.8125rem;
  margin-bottom: 1.5rem;
}

.Article-notes span {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.Article-notes a {
  color: inherit;
  text-decoration: none;
}

.Article-notice {
  color: #777;
  font-family: var(--sans);
  font-size: 0.8125rem;
  line-height: 1.5;
  max-width: 65ch;
  margin-bottom: 2rem;
}

.Article-notice a {
  color: inherit;
}

.Article-content {
}

.Article-content h3 {
  margin-top: 4rem;
  margin-bottom: 1.5rem;
  font-size: 1.3125rem;
  font-weight: 500;
  letter-spacing: -0.04rem;
  text-align: center;
}

.Article-content p {
  color: #181918;
  line-height: 145%;
  letter-spacing: -0.007rem;
}

.Article-content p + p {
  margin-top: 1rem;
}

.Article-content .MobileNotice {
  background-color: rgba(238, 202, 74, 0.25);
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 0.8125rem;
  text-align: center;
  line-height: 1.35;
}

.Article-content .MobileNotice span {
  display: block;
  max-width: 40ch;
  margin-left: auto;
  margin-right: auto;
}

.Article-content figure {
  font-family: var(--sans);
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.Article-footer {
  border-top: 1px #eee solid;
  margin-top: 4rem;
  margin-bottom: 9rem;
  padding-top: 2rem;
  color: #666;
  font-family: var(--sans);
  font-size: 0.8125rem;
  line-height: 1.45;
}

.Article-footer p + p {
  margin-top: 1rem;
}

.Article-footer a {
  color: inherit;
}

a.Article-datasetLink {
  color: #3f65ec;
  text-decoration: none;
}

.AnnotationReference {
  position: relative;
  top: -2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.12rem;
  height: 1.12rem;
  margin-right: 0.125rem;
  line-height: 1.15rem;
  border-radius: 50%;
  border: 1px currentColor solid;
  color: inherit;
  font-size: 0.72em;
  font-family: var(--sans);
  text-decoration: none;
}

.highlight {
  padding-left: 0.17rem;
  padding-right: 0.17rem;
  color: inherit;
  text-decoration: none;
}

.highlight.red {
  background-color: rgba(244, 110, 103, 0.45);
}

.highlight.purple {
  background-color: rgba(73, 118, 204, 0.25);
}

.highlight.orange {
  background-color: rgba(255, 140, 0, 0.18);
}

.Filters {
  text-align: center;
}

.Legend {
  text-align: left;
}

@media only screen and (min-width: 767px) {
  .Article-content figure {
    margin-top: 4rem;
    margin-bottom: 3rem;
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .Article-content .MobileNotice {
    display: none;
  }

  .ChartA-svg {
    margin-left: -20px;
  }
}
