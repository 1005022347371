.Dot {
  fill: rgba(20, 20, 20, 0.1);
}

.Dot--highlighted {
  stroke-width: 2px;
  stroke: #242424;
}

.Dot[data-defaulted] {
  fill: rgba(251, 72, 72, 0.65);
}

.Dot-defaultLine {
  fill: rgba(251, 72, 72, 0.65);
}

.Chart-axis .domain {
  stroke: transparent;
}

.Chart-axis .tick text {
  color: #444;
  font-family: var(--sans);
  font-size: 10px;
  font-variant-numeric: tabular-nums;
}

.Chart-yAxis .tick line {
  stroke: #eee;
}

.Chart-xAxis .tick line {
  stroke: #ccc;
}

.Chart-axisLabel {
  fill: currentColor;
  font-family: var(--sans);
  font-size: 14px;
  text-anchor: middle;
}

.annotation {
  text-shadow: -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff,
    2px 2px 0 #fff;
}

.DefaultEventMarker {
  transition: opacity 150ms;
}

.ChartA--highlighted .DefaultEventMarker {
  opacity: 0;
  pointer-events: none;
}

.DefaultEventMarker-label {
  background-color: white;
  font-size: 10px;
  text-anchor: middle;
}

.Tooltip {
  position: absolute;
  pointer-events: none;
  background-color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  text-align: left;
  padding: 1rem;
  font-size: 0.8125rem;
  width: 17rem;
  z-index: 9;
}

.Tooltip-title {
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.Tooltip-badge {
  margin-bottom: 0.45rem;
  border-radius: 4px;
  display: inline-block;
  color: white;
  background-color: #fb4848;
  font-size: 0.6875rem;
  font-weight: 500;
  padding: 0.1rem 0.45rem;
}

.Tooltip ul {
  list-style: none;
}

.Tooltip ul li span {
  color: #888;
  margin-right: 0.15rem;
}
